





































































import List from "@/components/List";
import { EventResource } from "@/resources";
import { Event } from "@/resources/interfaces";

export default class EventList extends List<Event> {
  name = "event";
  resource = EventResource;
  currentSort = "";
  currentSortOrder = "desc";
}
