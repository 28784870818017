























































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import moment from "moment";
import ws from "@/helpers/webSocket";
import {
  Booking,
  BookingStatus,
  BookingItem,
  TodoProductGroup
} from "@/resources/interfaces";
import { BookingResource } from "@/resources";
import FoodTodoBookingCard from "./FoodTodoBookingCard.vue";
// @ts-ignore
import LazyComponent from "v-lazy-component";
import FoodTodoProductGroupCard from "./FoodTodoProductGroupCard.vue";

@Component({
  components: { FoodTodoBookingCard, FoodTodoProductGroupCard, LazyComponent }
})
export default class FoodTodo extends Vue {
  bookings: Booking[] = [];
  finishedBookingIds: Set<string> = new Set();
  pinnedProductIds: string[] = [];

  isFullscreenEnabled = false;
  ignoreBookingUpdate: string | null = null;
  // expandedBookingIds: string[] = [];
  // bookingsItemHeight: Record<string, number[]> = {};
  // bookingsMinutes: Record<string, number> = {};
  showFinishedBookings = false;
  searchHistoryKeyword = "";
  mode: "bar" | "kitchen" | "all" = "bar";
  role: "produce" | "deliver" = "produce";
  group: "booking" | "product" = "booking";
  cols = 4;
  checkFinishInterval = 0;

  get todoBookings() {
    return this.bookings
      .filter(b => !this.finishedBookingIds.has(b.id))
      .map(booking => ({
        ...booking,
        items: (booking.items || []).filter(i => this.itemVisible(i))
      }))
      .filter(booking => booking.items.length)
      .sort((a, b) => (a.checkInAt < b.checkInAt ? -1 : 1));
  }

  get finishedBookings() {
    return Array.from(this.finishedBookingIds)
      .map(id => this.bookings.find(b => b.id === id))
      .filter(b => {
        if (!b || !b.items) return false;
        const s = this.searchHistoryKeyword.toLowerCase();
        if (
          this.searchHistoryKeyword &&
          !(b.pagerId || "").toLowerCase().includes(s) &&
          !(b.tableId || "").toLowerCase().includes(s) &&
          !b.items.some(i => i.name?.includes(s))
        ) {
          return false;
        }
        return true;
      })
      .sort((a, b) => ((a?.updatedAt || 0) < (b?.updatedAt || 0) ? 1 : -1));
  }

  get todoProductGroups() {
    const map: Map<string, TodoProductGroup> = new Map();

    for (const booking of this.todoBookings) {
      for (const item of booking.items) {
        if (!item.product || item.name?.match(/自助餐/)) continue;
        let productGroup = map.get(item.product);
        if (!productGroup) {
          productGroup = {
            product: {
              id: item.product,
              name: item.name,
              no: item.no,
              categories: item.productCategory?.split("|") || []
            },
            items: [],
            toProduceCount: 0,
            toDeliverCount: 0
          };
        }
        productGroup.items.push({
          bookingId: booking.id,
          bookingItemId: item._id || "",
          bookingRemarks: booking.remarks,
          quantity: item.quantity,
          pagerId: booking.pagerId,
          tableId: booking.tableId,
          comment: item.comment,
          checkInAt: booking.checkInAt,
          checkedAt: item.checkedAt,
          deliveredAt: item.deliveredAt
        });
        map.set(item.product, productGroup);
      }
    }

    const groups = Array.from(map.values());

    for (const group of groups) {
      group.toProduceCount = group.items
        .filter(i => !i.checkedAt && !i.deliveredAt)
        .reduce((c, i) => c + i.quantity, 0);
      group.toDeliverCount = group.items
        .filter(i => i.checkedAt && !i.deliveredAt)
        .reduce((c, i) => c + i.quantity, 0);
    }

    const getGroupSortValue = (group: TodoProductGroup) => {
      return this.pinnedProductIds.indexOf(group.product.id || "") || Infinity;
    };

    return groups.sort((a, b) =>
      getGroupSortValue(a) < getGroupSortValue(b) ? 1 : -1
    );
  }

  changeMode(mode: "bar" | "kitchen" | "all") {
    this.mode = mode;
    window.localStorage.setItem("foodTodo.mode", mode);
  }

  changeCols(cols: number) {
    this.cols = cols;
    window.localStorage.setItem("foodTodo.cols", cols.toString());
  }

  changeRole(role: "produce" | "deliver") {
    this.role = role;
    window.localStorage.setItem("foodTodo.role", role);
  }

  changeGroup(group: "booking" | "product") {
    this.group = group;
    window.localStorage.setItem("foodTodo.group", group);
  }

  itemVisible(item: BookingItem) {
    if (this.mode === "bar") {
      return item.dept === "吧台";
    } else if (this.mode === "kitchen") {
      return item.dept === "厨房";
    }
    return true;
  }

  async getBooking(id: string) {
    const booking = await BookingResource.get({ id });
    const index = this.bookings.findIndex(b => b.id === id);
    if (index < 0) {
      this.bookings.push(booking);
    } else {
      this.bookings.splice(index, 1, booking);
    }
  }

  async check({
    bookingId,
    itemId,
    revoke
  }: {
    bookingId: string;
    itemId: string;
    revoke: boolean;
  }) {
    // this.ignoreBookingUpdate = booking.id;
    await BookingResource.update(
      {
        id: bookingId,
        checkItem: itemId,
        revoke: revoke || undefined,
        deliver: this.role === "deliver" ? "true" : undefined
      },
      {}
    );
    // this.ignoreBookingUpdate = null;
  }

  pinProduct(id: string) {
    if (this.pinnedProductIds.includes(id)) {
      this.pinnedProductIds = this.pinnedProductIds.filter(i => i !== id);
    } else {
      this.pinnedProductIds.unshift(id);
    }
    this.updatePinnedProducts();
  }

  async finish(booking: Booking) {
    setTimeout(() => {
      // wait for animation
      this.finishedBookingIds.add(booking.id);
      this.updateFinishedBookingIds();
    }, 1e3);
    await BookingResource.update(
      { id: booking.id, mode: this.mode },
      { status: BookingStatus.FINISHED }
    );
  }

  async recover(booking: Booking) {
    this.finishedBookingIds.delete(booking.id);
    this.updateFinishedBookingIds();
    await BookingResource.update(
      { id: booking.id, mode: this.mode },
      { status: BookingStatus.IN_SERVICE }
    );
  }

  updateFinishedBookingIds() {
    this.finishedBookingIds = new Set(this.finishedBookingIds);
    window.localStorage.setItem(
      "foodTodo.finishedBookingIds",
      Array.from(this.finishedBookingIds).join(",")
    );
  }

  updatePinnedProducts() {
    window.localStorage.setItem(
      "foodTodo.pinnedProductIds",
      Array.from(this.pinnedProductIds).join(",")
    );
  }

  async toggleFinishedBookings() {
    this.showFinishedBookings = !this.showFinishedBookings;
  }

  async requestFullscreen() {
    await document.body.requestFullscreen();
    this.isFullscreenEnabled = true;
  }

  async exitFullscreen() {
    await document.exitFullscreen();
    this.isFullscreenEnabled = false;
  }

  async created() {
    this.mode =
      (window.localStorage.getItem("foodTodo.mode") as "bar" | "kitchen") ||
      "bar";
    this.role =
      (window.localStorage.getItem("foodTodo.role") as "produce" | "deliver") ||
      "produce";
    this.group =
      (window.localStorage.getItem("foodTodo.group") as
        | "booking"
        | "product") || "booking";
    this.cols = +(window.localStorage.getItem("foodTodo.cols") || 4);
    this.finishedBookingIds = new Set(
      window.localStorage.getItem("foodTodo.finishedBookingIds")?.split(",") ||
        [].filter(id => id)
    );
    this.pinnedProductIds =
      window.localStorage.getItem("foodTodo.pinnedProductIds")?.split(",") ||
      [].filter(id => id);
    this.bookings = await BookingResource.query({
      type: "food",
      date: moment().format("YYYY-MM-DD"),
      status: [BookingStatus.IN_SERVICE, BookingStatus.FINISHED].join(","),
      order: "createdAt",
      limit: false,
      foodTodo: true
    });
    if (!this.finishedBookingIds.size) {
      this.finishedBookingIds = new Set(
        this.bookings
          .filter(b => b.status === BookingStatus.FINISHED)
          .map(b => b.id)
      );
    }
  }

  startCheckFinish() {
    this.checkFinishInterval = window.setInterval(() => {
      this.bookings.forEach(booking => {
        if (
          this.finishedBookingIds.has(booking.id) ||
          booking.items?.some(i => !i.deliveredAt)
        )
          return;
        const lastDeliveredAt = booking.items
          ?.map(i => i.deliveredAt as Date)
          .sort((a, b) => (a > b ? -1 : 1))[0];
        const minutesAgo = moment().diff(lastDeliveredAt, "minutes", true);
        const minutesKeep = 20;
        console.log(booking.id, minutesAgo);
        if (minutesAgo < minutesKeep) return false;
        console.log("mark", booking.id, "into finish");
        this.finishedBookingIds.add(booking.id);
        this.updateFinishedBookingIds();
      });
    }, 2e4);
  }

  onFullscreenChange() {
    if (!document.fullscreenElement) {
      this.isFullscreenEnabled = false;
    }
  }

  listenBookingUpdate() {
    ws.$on("message", (msg: string) => {
      const [action, id] = msg.split(" ");
      console.log(action, id);
      if (action === "foodBookingUpdated" && this.ignoreBookingUpdate !== id) {
        this.getBooking(id);
        if (!this.bookings.some(b => b.id === id)) {
          (this.$refs.hintSound as HTMLAudioElement).play();
        }
      }
    });
  }

  activated() {
    this.listenBookingUpdate();
    this.startCheckFinish();
    document.addEventListener("fullscreenchange", this.onFullscreenChange);
  }
  deactivated() {
    ws.$off("message");
    window.clearInterval(this.checkFinishInterval);
    document.removeEventListener("fullscreenchange", this.onFullscreenChange);
  }
}
