





































import Vue from "vue";
import Component from "vue-class-component";
import eventBus from "@/helpers/eventBus";
import { Band, Booking } from "@/resources/interfaces";
import { Prop } from "vue-property-decorator";
import { BookingResource } from "@/resources";
import moment from "moment";

@Component
export default class BindBandsDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  show!: boolean;

  @Prop({ type: Boolean, default: false })
  activated!: boolean;

  @Prop()
  value!: Booking;

  @Prop()
  bookingId!: string;

  @Prop({ type: Number })
  maxKids!: number;

  @Prop({ type: Number })
  maxAdults!: number;

  type: "adult" | "kid" = "adult";

  bandTypeName = { adult: "成人", kid: "儿童" };

  bands: Band[] = [];

  allBandsBound = false;
  loading = false;

  get booking() {
    return this.value;
  }

  get removable() {
    if (this.$user.can("BOOKING_CANCEL_REVIEW")) return true;
    const timePass = moment().diff(
      `${this.booking.date} ${this.booking.checkInAt}`
    );
    return timePass < 3.6e6;
  }

  async save(band?: Band, remove = false) {
    this.loading = true;
    try {
      await BookingResource.update(
        { id: this.bookingId, bindBands: true, newBandNo: band?.no, remove },
        { bands: this.bands }
      );
      this.$emit("input", this.bands);
      if (band) {
        this.$notify({
          message: `手环已${remove ? "解绑" : "绑定"}：${
            band.type === "kid" ? "儿童" : "成人"
          } ${band.no}`,
          icon: "check",
          type: remove ? "warning" : "success"
        });
      }
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  cancel() {
    this.$emit("cancel");
    this.closeQrcodeScanner();
  }

  openQrcodeScanner() {
    eventBus.$emit("openQrcodeScanner", { closeOnScanned: false });
  }

  closeQrcodeScanner() {
    eventBus.$emit("closeQrcodeScanner");
  }

  bandScanned(s: string) {
    if (isNaN(+s)) {
      return;
    }
    if (!this.activated) return;
    if (this.bands.some(b => b.no === s)) return;
    const newBand = { type: this.type, no: s };
    this.bands.push(newBand);
    this.save(newBand);
    this.checkType(true);
  }

  countBands(type: "adult" | "kid") {
    return this.bands.filter(b => b.type === type).length;
  }

  removeBand(index: number) {
    const bandToRemove = this.bands[index];
    this.bands = this.bands.slice(0, index).concat(this.bands.slice(index + 1));
    this.save(bandToRemove, true);
  }

  checkType(closeIfAllBound = false) {
    if (this.type === "adult" && this.countBands("adult") >= this.maxAdults) {
      this.type = "kid";
    }
    if (this.type === "kid" && this.countBands("kid") >= this.maxKids) {
      this.type = "adult";
    }
    if (
      this.countBands("adult") >= this.maxAdults &&
      this.countBands("kid") >= this.maxKids
    ) {
      this.allBandsBound = true;
      if (closeIfAllBound) this.closeQrcodeScanner();
    }
  }

  created() {
    this.bands = this.booking.bands;
    this.checkType();
    eventBus.$on(["stringScanned", "qrcodeScanned"], this.bandScanned);
  }

  destroyed() {
    eventBus.$off(["stringScanned", "qrcodeScanned"], this.bandScanned);
  }
}
