







import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ColoredMinutes extends Vue {
  @Prop({ type: Number, required: true })
  value!: number;
}
