









































































































































import Vue from "vue";
import moment from "moment";
import { Watch, Component } from "vue-property-decorator";
import {
  Scene,
  User,
  Card,
  CardStatus,
  Coupon,
  PaymentGateway,
  BookingPrice,
  FlavorGroup,
  Flavor,
  PaymentGatewayGroup,
  BookingItem,
  Product
} from "@/resources/interfaces";
import {
  BookingPriceResource,
  UserResource,
  CardResource,
  BookingResource,
  ProductResource
} from "@/resources";
import { Tabs, PaymentDialog, GlobalKeyEnter } from "@/components";
import { watchPayments } from "@/helpers/watchPayment";
import eventBus from "@/helpers/eventBus";

interface ItemFlavorGroup extends FlavorGroup {
  flavors: (Flavor & { selected: boolean })[];
}

@Component({
  components: { Tabs, PaymentDialog, GlobalKeyEnter }
})
export default class RetailCashier extends Vue {
  customerKeyword = "";
  couponKeyword = "";
  cards: Card[] = [];
  nonSpecialOfferPrice = NaN;
  price = NaN;
  useBalance = false;
  paymentGateway: PaymentGateway | null = null;
  showPayment = false;
  submitLocked = false;
  categories: { name: string; order: number }[] = [];
  products: Product[] = [];

  customer: User | null = null;
  card: Card | null = null;
  coupon: Coupon | null = null;
  cartItems: BookingItem[] = [];
  remarks = "";
  modPrice: number | null = null;
  modPricePassword = "";

  get itemQuantity() {
    return this.cartItems.reduce(
      (quantity, item) => quantity + item.quantity,
      0
    );
  }

  get availableCoupons() {
    return this.$coupons.filter(coupon => {
      if (coupon.overQuantity && this.itemQuantity < coupon.overQuantity) {
        if (this.coupon?.id == coupon.id) {
          this.useCoupon(coupon);
        }
        return false;
      }
      if (
        coupon.productNos?.length &&
        !this.cartItems.some(
          item =>
            coupon.productNos?.includes(item.no || "") ||
            coupon.productNos?.includes(item.brand || "")
        )
      ) {
        return false;
      }
      if (coupon.start && Date.now() < new Date(coupon.start).valueOf()) {
        return false;
      }
      if (coupon.end && Date.now() > new Date(coupon.end).valueOf()) {
        return false;
      }
      return (
        coupon.scene === Scene.RETAIL &&
        (!coupon.stores.length ||
          coupon.stores.includes(this.$user.store?.id || ""))
      );
    });
  }

  get valid() {
    if (!this.cartItems.length) return false;
    return true;
  }

  @Watch("customerKeyword") async onCustomerKeywordUpdate() {
    if (!this.customerKeyword) {
      this.customer = null;
      this.cards = [];
      this.card = null;
      return;
    }

    const [users, bookings] = await Promise.all([
      UserResource.query({ keyword: this.customerKeyword }),
      BookingResource.query({
        date: moment().format("YYYY-MM-DD"),
        customerKeyword: this.customerKeyword
      })
    ]);

    if (users.length === 1) {
      this.customer = users[0];
    } else if (
      bookings.reduce((map, booking) => {
        if (booking.customer) {
          map.set(booking.customer.mobile, booking.customer);
        }
        return map;
      }, new Map()).size === 1
    ) {
      this.customer = bookings[0].customer;
    } else {
      this.customer = null;
      this.cards = [];
      this.useBalance = false;
    }

    if (this.customer) {
      if (this.customer.balance && !this.coupon) {
        this.useBalance = true;
      }
      this.getCards();
    }
  }

  async getMenu() {
    this.products = await ProductResource.query({
      type: "retail",
      ean: "null"
    });
    this.categories = Array.from(
      new Set(
        this.products.reduce(
          (cats, prod) => cats.concat(prod.categories),
          [] as string[]
        )
      )
    ).map(name => ({ name, order: 0 }));
  }

  onScanned(key: string) {
    if (this.showPayment) return false;
    if (key && key.length >= 12) {
      this.addProductFromEan(key);
    } else if (key) {
      this.customerKeyword = key;
    } else if (this.valid) {
      this.showPayment = true;
    }
  }

  addProduct(product: Product) {
    const itemInCart = this.cartItems.find(i => i.product === product.id);
    if (itemInCart) {
      itemInCart.quantity++;
    } else {
      this.cartItems.push({
        no: product.no,
        product: product.id,
        name: product.name,
        brand: product.brand,
        sellPrice: product.sellPrice,
        quantity: 1,
        comment: ""
      });
    }
    this.getPrice();
  }

  async addProductFromEan(ean: string) {
    const products = await ProductResource.query({ ean });
    if (!products.length) {
      this.$notify({
        message: `未找到产品：${ean}`,
        icon: "add_alert",
        type: "warning"
      });
      return;
    } else if (products.length > 1) {
      this.$notify({
        message: `产品条码不唯一：${ean}`,
        icon: "add_alert",
        type: "warning"
      });
      return;
    }
    this.addProduct(products[0]);
  }

  addItem(item: BookingItem, by = 1) {
    item.quantity += by;
    this.getPrice();
  }

  removeItem(item: BookingItem) {
    this.cartItems = this.cartItems.filter(i => i.product !== item.product);
    this.getPrice();
  }

  goCustomer() {
    if (!this.customer) return;
    this.$router.push("/user/" + this.customer.id);
  }

  async getPrice() {
    const bookingPrice = (await BookingPriceResource.create({
      type: Scene.RETAIL,
      items: this.cartItems,
      store: this.$user.store || null,
      card: this.card,
      coupon: this.coupon,
      price: this.modPrice === null ? undefined : this.modPrice
    })) as BookingPrice;
    this.price = bookingPrice.price;
    this.nonSpecialOfferPrice =
      bookingPrice.nonSpecialOfferPrice === undefined
        ? NaN
        : bookingPrice.nonSpecialOfferPrice;
  }

  async getCards() {
    if (!this.customer) return;
    const cards = await CardResource.query({
      type: "coupon,balance",
      customer: this.customer.id,
      status: CardStatus.ACTIVATED,
      scene: Scene.RETAIL
    });
    this.cards = cards.filter(card => this.cardVisible(card));
  }

  cardVisible(card: Card) {
    if (card.status !== CardStatus.ACTIVATED) return false;
    if (card.start && new Date(card.start) > moment().endOf("day").toDate()) {
      return false;
    }
    return true;
  }

  cardDisabled(card: Card) {
    if (card.type === "coupon") {
      if (
        card.overPrice &&
        (!this.nonSpecialOfferPrice ||
          this.nonSpecialOfferPrice < card.overPrice)
      ) {
        return true;
      }
    }
    return false;
  }

  toggleUseBalance() {
    if (!this.useBalance) {
      this.useBalance = true;
      this.card = null;
      this.coupon = null;
    } else {
      this.useBalance = false;
      if (this.card?.type === "balance") {
        this.useCard(false);
      }
    }
  }

  useCard(card?: Card | false) {
    if (!card || this.usingCard(card)) {
      console.log("UseCard null");
      this.card = null;
    } else {
      this.usePaymentGateway(false);
      this.card = card;
      if (this.card.type === "balance") {
        this.useBalance = true;
        this.coupon = null;
      } else {
        this.useBalance = false;
      }
    }
    this.getPrice();
  }

  usingCard(card: Card) {
    return this.card?.id === card.id;
  }

  matchCoupon(slug: string) {
    const coupon = this.$coupons.find(coupon => coupon.slug === slug);
    if (coupon) {
      this.coupon = coupon;
      this.couponKeyword = "";
      this.getPrice();
    }
  }

  useCoupon(coupon: Coupon | false) {
    if (!coupon || this.coupon?.id === coupon.id) {
      this.coupon = null;
    } else {
      this.coupon = coupon;
      this.useBalance = false;
      if (this.card?.type === "balance") {
        this.useCard(false);
      }
    }
    this.getPrice();
  }

  usingCoupon(coupon: Coupon) {
    return this.coupon?.id === coupon.id;
  }

  usePaymentGateway(gateway?: PaymentGateway | false) {
    if (!gateway || this.usingPaymentGateway(gateway)) {
      console.log("UsePaymentGateway null");
      this.paymentGateway = null;
    } else {
      this.paymentGateway = gateway;
    }
  }

  usingPaymentGateway(gateway: PaymentGateway) {
    return this.paymentGateway === gateway;
  }

  async pay(paymentGroups: PaymentGatewayGroup[]) {
    if (this.submitLocked) return;
    this.submitLocked = true;
    const party = this.$route.query.party as string | undefined;
    try {
      const booking = await BookingResource.create(
        {
          type: Scene.RETAIL,
          items: this.cartItems,
          card: this.card,
          coupon: this.coupon,
          customer: (this.customer?.id as unknown as User) || null,
          price: this.modPrice === null ? undefined : this.modPrice,
          remarks: this.remarks,
          party
        },
        {
          useBalance: this.useBalance ? undefined : "false",
          paymentGateways: party
            ? undefined
            : paymentGroups
                .filter(g => g.amount)
                .map(
                  g =>
                    `${g.gateway}:${g.amount}${
                      g.payCode ? "-" + g.payCode : ""
                    }`
                )
                .join(","),
          paymentGateway: PaymentGateway.Ar,
          modPricePassword: this.modPricePassword || undefined
        }
      );
      await watchPayments(booking.payments || []);
      this.$notify({
        message: "下单成功",
        icon: "add_alert",
        type: "success"
      });
      this.cards = [];
      this.nonSpecialOfferPrice = NaN;
      this.price = NaN;
      this.useBalance = false;
      this.paymentGateway = null;
      this.remarks = "";
      this.customer = null;
      this.card = null;
      this.coupon = null;
      this.cartItems = [];
      this.showPayment = false;
      this.customerKeyword = "";
      this.submitLocked = false;
    } catch (e) {
      this.submitLocked = false;
      throw e;
    }
  }

  activated() {
    if (this.$route.query.customer) {
      this.customerKeyword = this.$route.query.customer as string;
    }
    eventBus.$on(["qrcodeScanned", "stringScanned"], this.onScanned);
    this.getCards();
    this.getMenu();
  }

  deactivated() {
    eventBus.$off(["qrcodeScanned", "stringScanned"], this.onScanned);
  }
}
