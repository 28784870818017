



















































































import List from "@/components/List";
import { CouponResource } from "@/resources";
import { Coupon } from "@/resources/interfaces";
import Component from "vue-class-component";

@Component
export default class CouponList extends List<Coupon> {
  name = "coupon";
  resource = CouponResource;
  autoCompletes = [{ key: "customerKeyword", minLength: 4 }];
  saveSearchQueryKeys = ["scene"];
  created() {
    this.searchQuery = {
      enabled: true,
      ...this.searchQuery
    };
  }
}
