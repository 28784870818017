























































import Vue from "vue";
import { Component } from "vue-property-decorator";
import moment from "moment";
import { QueueItem } from "@/resources/interfaces";
import { http } from "@/resources";
import { confirm, promptInput } from "@/helpers/sweetAlert";

@Component
export default class FoodCashier extends Vue {
  queue: QueueItem[] = [];
  stateName = {
    queued: "排队中",
    canceled: "已取消",
    notified: "已通知",
    confirmed: "已确认",
    calling: "呼叫中",
    called: "已呼叫",
    "call rejected": "呼叫失败",
    settled: "已入座"
  };
  actionName = {
    call: "呼叫",
    cancel: "取消",
    settle: "就座",
    notify: "通知",
    postpone: "过号"
  };
  interval: any = null;
  get queueFiltered() {
    return this.queue.filter(
      item => !["canceled", "settled"].includes(item.state)
    );
  }
  async updateQueue() {
    this.queue = (await http.get("/store-food-queue")).data;
    this.queue.forEach(item => {
      if (item.notifiedAt) {
        item.notifiedFor = moment
          .utc(moment().diff(moment(item.notifiedAt), "milliseconds"))
          .format("mm:ss");
      }
      if (item.calledAt) {
        item.calledFor = moment
          .utc(moment().diff(moment(item.calledAt), "milliseconds"))
          .format("mm:ss");
      }
    });
  }
  async sendAction(
    item: QueueItem,
    action: "call" | "cancel" | "settle" | "notify"
  ) {
    let tableInfo: string | undefined = undefined;

    if (action === "notify") {
      tableInfo = await promptInput(
        this.actionName[action],
        `请填写为${item.no}号 ${item.mobile} 安排的座位信息（可选）`
      );
      if (tableInfo === undefined) {
        return;
      }
    } else {
      if (
        !(await confirm(
          this.actionName[action],
          `确认对${item.no}号 ${item.mobile}执行“${this.actionName[action]}”操作？`
        ))
      )
        return;
    }

    this.queue = (
      await http.put(
        "/store-food-queue",
        { tableInfo },
        { params: { no: item.no, action } }
      )
    ).data;

    this.$notify({
      message: "操作完成",
      icon: "add_alert",
      type: "success"
    });
  }
  created() {
    this.updateQueue();
  }
  activated() {
    this.interval = setInterval(this.updateQueue, 5000);
  }
  deactivated() {
    clearInterval(this.interval);
    this.interval = null;
  }
}
