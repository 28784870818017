
























































































































































import List from "@/components/List";
import { CardResource, http } from "@/resources";
import { Card, PaymentGateway } from "@/resources/interfaces";
import Component from "vue-class-component";
import moment from "moment";

@Component
export default class CardList extends List<Card> {
  name = "card";
  resource = CardResource;
  autoCompletes = [{ key: "customerKeyword", minLength: 4 }];
  searchQuery: Record<string, any> = {
    date: "",
    dateEnd: "",
    type: "",
    isContract: "",
    sellAtStore: "",
    stores: "",
    status: []
  };
  saveSearchQueryKeys = [
    "type",
    "isContract",
    "sellAtStore",
    "stores",
    "status"
  ];
  // timesCardStatsByStore: {
  //   storeNames: string;
  //   times: number;
  //   priceLeft: number;
  // }[] = [];
  importPaymentGateway: PaymentGateway = PaymentGateway.Pos;

  getCardTypeLink(item: Card) {
    return "/card-type/" + encodeURIComponent(item.slug);
  }

  getStoreName(storeId: string) {
    return this.$stores.find(s => s.id === storeId)?.name.substr(0, 2);
  }

  download() {
    const params: Record<string, any> = {
      token: window.localStorage.token,
      ...this.query
    };
    const queryString = Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null)
      .map(key => `${key}=${params[key]}`)
      .join("&");
    window.location.href =
      process.env.VUE_APP_API_BASE + "/card-sheet?" + queryString;
  }

  selectFile() {
    (this.$refs["file-upload"] as HTMLInputElement).click();
  }
  onFileChange(e: any) {
    let files = (e.target?.files || e.dataTransfer?.files) as FileList;
    if (!files?.length) return;
    Array.from(files).forEach(file => {
      this.uploadFile(file);
    });
  }
  async uploadFile(file: Blob) {
    const formData = new FormData();
    formData.append("file", file);
    try {
      await http.post(`card-import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      this.queryData();
    } catch (e) {
      //
    }
    (this.$refs["file-upload"] as HTMLInputElement).value = "";
  }

  async created() {
    if (this.$route.query.slug) {
      this.searchQuery.slug = this.$route.query.slug;
    } else if (!this.searchQuery.date) {
      this.searchQuery.date = moment().format("YYYY-MM-DD");
    }
    // this.timesCardStatsByStore = (await http.get("stats/times-card")).data;
  }
}
