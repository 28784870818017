export default function castEmbedded(
  input: Record<string, any>,
  fields: string[]
) {
  const doc = { ...input };
  for (const field of fields) {
    if (doc[field] === undefined || doc[field] === null) {
      continue;
    }
    if (Array.isArray(doc[field])) {
      doc[field] = (doc[field] as { id?: string; _id?: string }[]).map(
        i => i._id || i.id
      );
    } else if (doc[field]._id || doc[field].id) {
      doc[field] = doc[field]._id || doc[field].id;
      // console.log("cast", field, "of", JSON.stringify(doc), "to", doc[field]);
    }
  }
  return doc;
}
