










































































































































































































































import Vue from "vue";
import moment from "moment";
import { Watch, Component, Prop } from "vue-property-decorator";
import {
  Scene,
  User,
  Card,
  CardStatus,
  Coupon,
  PaymentGateway,
  BookingPrice,
  FlavorGroup,
  Flavor,
  PaymentGatewayGroup,
  Product,
  Store,
  SetItem
} from "@/resources/interfaces";
import {
  BookingPriceResource,
  UserResource,
  CardResource,
  BookingResource,
  ProductResource
} from "@/resources";
import { Tabs, PaymentDialog, GlobalKeyEnter } from "@/components";
import { watchPayments } from "@/helpers/watchPayment";
import eventBus from "@/helpers/eventBus";
import { confirm, alert } from "@/helpers/sweetAlert";

interface ItemFlavorGroup extends FlavorGroup {
  flavors: (Flavor & { selected: boolean })[];
}

interface CartItem {
  id?: string;
  product: string;
  productNo?: string;
  productCategory: string;
  productName: string;
  sellPrice: number;
  extraPrice?: number;
  quantity: number;
  remarks?: string;
  comment: string;
  flavorGroups: ItemFlavorGroup[];
  setItems?: SetItem[];
  isSet?: boolean;
  parent?: string;
  setItemName?: string;
  subItems?: CartItem[];
}

@Component({
  components: { Tabs, PaymentDialog, GlobalKeyEnter }
})
export default class FoodCashier extends Vue {
  @Prop({ type: String, default: Scene.FOOD })
  type!: Scene;

  store: Store | null = null;
  categories: { name: string; order: number }[] = [];
  products: Product[] = [];

  customerKeyword = "";
  couponKeyword = "";
  cards: Card[] = [];
  nonSpecialOfferPrice = NaN;
  price = NaN;
  useBalance = false;
  paymentGateway: PaymentGateway | null = null;
  dialogItem: CartItem | null = null;
  parentDialogItem: CartItem | null = null;
  showPayment = false;
  submitLocked = false;
  gettingPrice = false;

  customer: User | null = null;
  card: Card | null = null;
  coupon: Coupon | null = null;
  cartItems: CartItem[] = [];
  pagerId = "0";
  remarks = "";

  get availableCoupons() {
    return this.$coupons.filter(coupon => {
      return (
        !coupon.stores.length || coupon.stores.includes(this.store?.id || "")
      );
    });
  }

  get setCoupons() {
    return this.availableCoupons.filter(
      coupon => coupon.scene === "food" && coupon.type === "set"
    );
  }

  get dialogValid() {
    if (!this.dialogItem) return false;
    if (this.dialogItem.quantity < 1) return false;
    if (
      this.dialogItem.flavorGroups.some(
        group => group.required && group.flavors.every(f => !f.selected)
      )
    ) {
      return false;
    }
    if (this.dialogItem.isSet) {
      return this.dialogItem.setItems?.every(setItem => {
        if (!this.dialogItem) return false;
        if (setItem.isFixedProducts) return true;
        return (
          setItem.quantity ===
          this.getCartItemSetItems(this.dialogItem, setItem.name).length
        );
      });
    }
    return true;
  }

  get valid() {
    if (!this.cartItems.length) return false;
    if (this.gettingPrice) return false;
    return true;
  }

  get dialogItemPrice() {
    if (!this.dialogItem) return NaN;
    let price = this.dialogItem.sellPrice;
    this.dialogItem.flavorGroups.forEach(group => {
      group.flavors.forEach(flavor => {
        if (flavor.selected && flavor.extraPrice) {
          price = price + flavor.extraPrice;
        }
      });
    });
    return +(price * this.dialogItem.quantity).toFixed(2);
  }

  @Watch("customerKeyword") async onCustomerKeywordUpdate() {
    if (!this.customerKeyword) {
      this.customer = null;
      this.cards = [];
      this.card = null;
      return;
    }

    const [users, bookings, bandBookings] = await Promise.all([
      UserResource.query({ keyword: this.customerKeyword }),
      BookingResource.query({
        date: moment().format("YYYY-MM-DD"),
        customerKeyword: this.customerKeyword
      }),
      BookingResource.query({
        date: moment().format("YYYY-MM-DD"),
        bandNo: this.customerKeyword
      })
    ]);

    if (users.length === 1) {
      this.customer = users[0];
    } else if (
      bookings.reduce((map, booking) => {
        if (booking.customer) {
          map.set(booking.customer.mobile, booking.customer);
        }
        return map;
      }, new Map()).size === 1
    ) {
      this.customer = bookings[0].customer;
    } else if (
      bandBookings.reduce((map, booking) => {
        if (booking.customer) {
          map.set(booking.customer.mobile, booking.customer);
        }
        return map;
      }, new Map()).size === 1
    ) {
      this.customer = bandBookings[0].customer;
    } else {
      this.customer = null;
      this.cards = [];
      this.useBalance = false;
    }

    if (this.customer) {
      if (this.customer.balance && !this.$route.query.party) {
        this.useBalance = true;
      }
      this.getCards();
    }
  }

  onStringScanned(key: string) {
    if (this.showPayment || !key) return;
    console.log("FoodCashier:onGlobalKeyEnter", key);
    if (key) {
      this.customerKeyword = key;
    } else if (this.valid) {
      this.showPayment = true;
    }
  }

  async getMenu() {
    this.products = await ProductResource.query({
      type: this.type === Scene.EVENT ? "lab" : "food",
      limit: false,
      store: this.store?.id
    });
    this.products = this.products.filter(p => !isNaN(this.getProductPrice(p)));
    this.categories = Array.from(
      new Set(
        this.products.reduce(
          (cats, prod) => cats.concat(prod.categories),
          [] as string[]
        )
      )
    ).map(name => ({ name, order: 0 }));
    this.categories.forEach(category => {
      const order = this.$config.foodMenuOrder?.[category.name];
      if (order) {
        category.order = order;
      }
    });
    this.categories.sort((a, b) => (a.order < b.order ? 1 : -1));
    this.categories = this.categories.filter(c => c.order !== -2);
  }

  getProductPrice(product: Product) {
    const supply = product.supplies.find(s => s.store === this.store?.id);
    if (!supply || supply.disabled || product.sellPrice === undefined) {
      return NaN;
    }
    return supply.price || product.sellPrice;
  }

  chooseFlavor(group: ItemFlavorGroup, flavor: Flavor) {
    if (!this.dialogItem) return;
    const itemFlavorGroup = this.dialogItem.flavorGroups.find(
      g => g.name === group.name
    );
    if (!itemFlavorGroup) return;
    const itemFlavor = itemFlavorGroup.flavors.find(
      f => f.name === flavor.name
    );
    if (!itemFlavor) return;
    if (!group.multiple && !itemFlavor?.selected) {
      itemFlavorGroup.flavors.forEach(f => (f.selected = false));
    }
    itemFlavor.selected = !itemFlavor.selected;
  }

  isFlavorChosen(group: ItemFlavorGroup, flavor: Flavor) {
    if (!this.dialogItem) return;
    const itemFlavorGroup = this.dialogItem.flavorGroups.find(
      g => g.name === group.name
    );
    if (!itemFlavorGroup) return;
    const itemFlavor = itemFlavorGroup.flavors.find(
      f => f.name === flavor.name
    );
    return itemFlavor?.selected;
  }

  getCartItemSetItems(cartItem: CartItem, setItemName: string) {
    return cartItem.subItems?.filter(i => i.setItemName === setItemName) || [];
  }

  removeCartItemSubCartItem(cartItem: CartItem, subItemId: string) {
    cartItem.subItems = cartItem.subItems?.filter(i => i.id !== subItemId);
  }

  addItemToCart(item: CartItem) {
    const commentSegs: string[] = [];
    let extraPrice = 0;

    item.flavorGroups.forEach(group => {
      group.flavors.forEach(flavor => {
        if (flavor.selected) {
          commentSegs.push(flavor.name);
          extraPrice += flavor.extraPrice || 0;
        }
      });
    });

    if (item.remarks) {
      commentSegs.push(item.remarks);
    }

    const newItem = {
      id: item.id,
      product: item.product,
      productNo: item.productNo,
      productCategory: item.productCategory,
      productName: item.productName,
      sellPrice: item.sellPrice,
      extraPrice,
      quantity: item.quantity,
      comment: commentSegs.join(" "),
      flavorGroups: item.flavorGroups,
      parent: item.parent,
      setItemName: item.setItemName,
      subItems: item.subItems,
      isSet: item.isSet
    };

    const parentItem =
      this.dialogItem?.id === item.parent
        ? this.dialogItem
        : this.parentDialogItem?.id === item.parent
        ? this.parentDialogItem
        : null;

    const cartItems = parentItem?.subItems
      ? parentItem.subItems
      : this.cartItems;

    const itemInCart = cartItems.find(
      item =>
        !item.isSet &&
        item.product === newItem.product &&
        (newItem.id === item.id || item.comment === newItem.comment)
    );

    if (itemInCart) {
      if (itemInCart.id === newItem.id) {
        Object.assign(itemInCart, newItem);
      } else {
        itemInCart.quantity += newItem.quantity;
      }
    } else {
      cartItems.push(newItem);
    }

    this.getPrice();
  }

  confirmDialogItem() {
    if (!this.dialogItem) return;

    this.addItemToCart(this.dialogItem);

    this.dialogItem = null;
    if (this.parentDialogItem) {
      this.dialogItem = this.parentDialogItem;
      this.parentDialogItem = null;
      return;
    }
  }

  cancelDialogItem() {
    if (this.dialogItem?.isSet) {
      this.cartItems = this.cartItems.filter(
        i => i.parent !== this.dialogItem?.id
      );
    }
    this.dialogItem = null;
    if (this.parentDialogItem) {
      this.dialogItem = this.parentDialogItem;
      this.parentDialogItem = null;
    }
  }

  removeDialogItem() {
    this.cartItems = this.cartItems.filter(
      item =>
        item.id !== this.dialogItem?.id && item.parent !== this.dialogItem?.id
    );
    this.dialogItem = null;
  }

  addItem(item: CartItem, by = 1) {
    item.quantity += by;
    this.getPrice();
  }

  removeItem(item: CartItem) {
    this.cartItems = this.cartItems.filter(i => i.id !== item?.id);
    this.getPrice();
  }

  goCustomer() {
    if (!this.customer) return;
    this.$router.push("/user/" + this.customer.id);
  }

  chooseProduct(
    product: Product,
    parentCartItem?: string,
    setItemName?: string,
    forceAddToCart = false,
    sequence?: number,
    quantity = 1
  ) {
    const cartItem = {
      id:
        Date.now().toString() + (sequence === undefined ? "" : `-${sequence}`),
      product: product.id,
      productNo: product.no,
      productCategory: product.categories.join("|"),
      productName: product.name,
      sellPrice: this.getProductPrice(product),
      quantity,
      comment: "",
      flavorGroups:
        product.flavorGroups?.map(g => ({
          ...g,
          flavors: g.flavors.map((f, i) => ({
            ...f,
            selected: !i && !!g.required
          }))
        })) || [],
      setItems: product.setItems.map(i => ({
        ...i,
        subItems: []
      })),
      isSet: product.isSet,
      parent: parentCartItem,
      setItemName,
      subItems: product.isSet ? [] : undefined
    };

    if ((product.flavorGroups?.length || product.isSet) && !forceAddToCart) {
      if (parentCartItem && this.dialogItem) {
        this.parentDialogItem = this.dialogItem;
      }
      this.dialogItem = cartItem;
      if (cartItem.isSet) {
        cartItem.setItems?.forEach(setItem => {
          if (setItem.isFixedProducts) {
            (setItem.products as (Product & { quantity: number })[]).forEach(
              (product, index) => {
                this.chooseProduct(
                  product,
                  cartItem.id,
                  setItem.name,
                  true,
                  index,
                  product.quantity
                );
              }
            );
          }
        });
      }
    } else {
      this.addItemToCart(cartItem);
    }
  }

  async getPrice() {
    this.gettingPrice = true;
    const bookingPrice = (await BookingPriceResource.create({
      type: this.type,
      items: this.cartItems,
      store: this.store || null,
      card: this.card,
      coupon: this.coupon,
      pagerId: this.pagerId
    })) as BookingPrice;
    this.price = bookingPrice.price;
    this.nonSpecialOfferPrice =
      bookingPrice.nonSpecialOfferPrice === undefined
        ? NaN
        : bookingPrice.nonSpecialOfferPrice;
    if (this.card && this.cardDisabled(this.card)) {
      this.card = null;
    }
    this.gettingPrice = false;
  }

  async getCards() {
    if (!this.customer) return;
    const cards = await CardResource.query({
      // type: "coupon,times,period",
      customer: this.customer.id,
      status: CardStatus.ACTIVATED,
      scene: this.type
    });
    this.cards = cards.filter(card => this.cardVisible(card));
  }

  cardVisible(card: Card) {
    if (card.status !== CardStatus.ACTIVATED) return false;
    if (card.start && new Date(card.start) > moment().endOf("day").toDate()) {
      return false;
    }
    return true;
  }

  itemMatchesNo(item: CartItem, no: string) {
    const [main, flavor] = no.split(":");
    if (flavor && !item.comment?.includes(flavor)) {
      return false;
    }
    if (
      ![item.productName, item.productCategory, item.productNo].includes(main)
    ) {
      return false;
    }
    return true;
  }

  cardDisabled(card: Card) {
    let disabled = false;
    if (card.type === "coupon") {
      if (
        card.overPrice &&
        (!this.nonSpecialOfferPrice ||
          this.nonSpecialOfferPrice < card.overPrice)
      ) {
        disabled = true;
      }
    }
    if (card.productNos?.length) {
      const nos = card.productNos;
      if (
        !this.cartItems?.some(i => nos.some(no => this.itemMatchesNo(i, no)))
      ) {
        disabled = true;
      }
    }
    if (disabled && this.usingCard(card)) {
      this.useCard(false);
    }
    return disabled;
  }

  toggleUseBalance() {
    if (!this.useBalance) {
      this.useBalance = true;
      // this.card = null;
    } else {
      this.useBalance = false;
      if (this.card?.type === "balance" && !this.card?.balanceInCard) {
        this.useCard(false);
      }
    }
  }

  useCard(card?: Card | false) {
    if (!card || this.usingCard(card)) {
      console.log("UseCard null");
      this.card = null;
    } else {
      this.usePaymentGateway(false);
      this.card = card;
      if (this.card.type === "balance" && !this.card.balanceInCard) {
        this.useBalance = true;
        if (this.coupon) {
          this.coupon = null;
        }
      }
      // this.useBalance = false;
    }
    this.getPrice();
  }

  usingCard(card: Card) {
    return this.card?.id === card.id;
  }

  async matchCoupon(slug: string) {
    if (!slug) return;
    const coupon = this.$coupons.find(coupon => coupon.slug === slug);
    if (coupon) {
      this.coupon = coupon;
      this.couponKeyword = "";
      if (this.card?.type === "balance") {
        this.useCard(false);
      }
      this.getPrice();
      return;
    }
    const issuableCardType = this.$cardTypes.find(
      ct => ct.slug === slug && ct.openForBar
    );
    if (issuableCardType) {
      this.couponKeyword = "";
      if (!this.customer) {
        await alert("必须关联客户才能发券", "", null, "error");
        return;
      }
      if (
        !(await confirm(
          `${issuableCardType.title}`,
          `请确定已核验对应的平台券`,
          "发券"
        ))
      ) {
        return;
      }
      await CardResource.create(
        {
          slug: issuableCardType.slug,
          // @ts-ignore
          customer: this.customer.id
        },
        { paymentGateway: PaymentGateway.Agency }
      );
      this.getCards();
    }
  }

  removeCoupon() {
    this.coupon = null;
    this.getPrice();
  }

  usePaymentGateway(gateway?: PaymentGateway | false) {
    if (!gateway || this.usingPaymentGateway(gateway)) {
      console.log("UsePaymentGateway null");
      this.paymentGateway = null;
    } else {
      this.paymentGateway = gateway;
    }
  }

  usingPaymentGateway(gateway: PaymentGateway) {
    return this.paymentGateway === gateway;
  }

  async pay(paymentGroups: PaymentGatewayGroup[]) {
    if (this.submitLocked) return;
    this.submitLocked = true;
    const party = this.$route.query.party as string | undefined;
    try {
      const booking = await BookingResource.create(
        {
          type: this.type,
          items: this.cartItems,
          card: this.card,
          coupon: this.coupon,
          customer: (this.customer?.id as unknown as User) || null,
          pagerId: this.pagerId,
          remarks: this.remarks,
          party
        },
        {
          useBalance: this.useBalance ? undefined : "false",
          paymentGateways: party
            ? undefined
            : paymentGroups
                .filter(g => g.amount)
                .map(
                  g =>
                    `${g.gateway}:${g.amount}${
                      g.payCode ? "-" + g.payCode : ""
                    }`
                )
                .join(","),
          paymentGateway: PaymentGateway.Ar
        }
      );
      await watchPayments(booking.payments || []);
      this.$notify({
        message: "下单成功",
        icon: "add_alert",
        type: "success"
      });
      this.cards = [];
      this.nonSpecialOfferPrice = NaN;
      this.price = NaN;
      this.useBalance = false;
      this.paymentGateway = null;
      this.pagerId = "0";
      this.remarks = "";
      this.customer = null;
      this.card = null;
      this.coupon = null;
      this.cartItems = [];
      this.dialogItem = null;
      this.showPayment = false;
      this.customerKeyword = "";
      this.submitLocked = false;
    } catch (e) {
      this.submitLocked = false;
      throw e;
    }
  }

  activated() {
    this.store = this.$user?.store || null;
    this.getMenu();
    this.getCards();
    if (this.$route.query.customer) {
      this.customerKeyword = this.$route.query.customer as string;
    }
    eventBus.$on(["stringScanned", "qrcodeScanned"], this.onStringScanned);
  }

  deactivated() {
    eventBus.$off(["stringScanned", "qrcodeScanned"], this.onStringScanned);
  }
}
