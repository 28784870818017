



































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { EventResource, BookingResource } from "@/resources";
import { Event, Booking } from "@/resources/interfaces";
import { Editor, Poster } from "@/components";
import { confirm } from "@/helpers/sweetAlert";
import FlavorGroups from "@/components/FlavorGroups.vue";

@Component({
  components: {
    Editor,
    Poster,
    FlavorGroups
  }
})
export default class EventDetail extends Vue {
  event: Partial<Event> = {};
  bookings: Booking[] = [];
  async save() {
    this.event = await EventResource.save(this.event);
    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });
    if (this.$route.params.id === "add") {
      this.$router.replace(`/event/${this.event.id}`);
    }
  }
  async remove() {
    if (
      !(await confirm(
        "确定要删除这个活动",
        `这个操作不可撤销`,
        "确定删除",
        "error"
      ))
    )
      return;
    await EventResource.delete({ id: this.event.id });
    this.$router.back();
  }

  async clone() {
    if (
      !(await confirm(
        "确定要复制这个活动",
        `当前活动的修改将不被保存`,
        "复制并离开",
        "warning"
      ))
    )
      return;
    this.$router.push("/event/add?clone=" + this.event.id);
  }

  goEventBookings() {
    this.$router.push("/booking/event?event=" + this.event.id);
  }

  @Watch("event.date") onEventDateUpdate(v: Date | string) {
    if (!v.constructor || v.constructor.name !== "Date") {
      this.event.date = new Date((this.event as Event).date);
    }
  }
  @Watch("event.store") onEventStoreUpdate(v: string | false) {
    if (typeof v === "object" && v) {
      // @ts-ignore
      this.event.store = this.event.store.id;
    } else if (v === false) {
      this.event.store = null;
    }
  }
  async mounted() {
    if (this.$route.params.id !== "add") {
      this.event = await EventResource.get({ id: this.$route.params.id });
      this.bookings = await BookingResource.query({
        event: this.$route.params.id,
        status: "pending,booked,in_service,finished"
      });
    }
    if (this.$route.query.clone) {
      const origin = await EventResource.get({ id: this.$route.query.clone });
      this.event = {
        title: origin.title,
        ipCharacter: origin.ipCharacter,
        date: origin.date,
        kidAgeRange: origin.kidAgeRange,
        store: origin.store,
        price: origin.price,
        priceInPoints: origin.priceInPoints,
        kidsCountMax: origin.kidsCountMax,
        order: origin.order,
        tags: origin.tags,
        content: origin.content
      };
    }
    if (this.$route.params.id === "add" && this.$user.store) {
      // @ts-ignore
      this.event = { ...this.event, store: this.$user.store.id };
    }
  }
}
