










































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { TodoProductGroup, TodoProductGroupItem } from "@/resources/interfaces";
import ColoredMinutes from "./ColoredMinutes.vue";

@Component({ components: { ColoredMinutes } })
export default class FoodTodoProductGroupCard extends Vue {
  @Prop({ required: true })
  value!: TodoProductGroup;

  @Prop({ type: String, default: "produce" })
  role!: "produce" | "deliver";

  @Prop({ type: Boolean, default: false })
  pinned!: boolean;

  expanded = false;
  minutes = NaN;
  itemsScrollHeight = NaN;
  itemsClientHeight = NaN;
  calcBookingMinutesInterval = 0;
  checkedItemIds: string[] = [];
  deliveredItemIds: string[] = [];

  tick = new Date();

  get group() {
    this.checkedItemIds =
      this.value.items
        ?.filter(i => i.checkedAt)
        .map(i => i.bookingItemId || "") || [];
    this.deliveredItemIds =
      this.value.items
        ?.filter(i => i.deliveredAt)
        .map(i => i.bookingItemId || "") || [];
    return this.value;
  }

  @Watch("group", { immediate: true }) onGroupUpdate() {
    // console.log("onBookingUpdate", this.group.id);
    this.$nextTick().then(() => {
      this.calcItemHeight();
    });
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  get expandable() {
    return this.itemsScrollHeight > this.itemsClientHeight;
  }

  get isLongTime() {
    return this.minutes >= 20;
  }

  get itemsHeight() {
    return this.expanded
      ? this.itemsScrollHeight + "px"
      : this.expandable
      ? this.itemsClientHeight + "px"
      : "";
  }

  get items() {
    const finishedItemIds =
      this.role === "produce" ? this.checkedItemIds : this.deliveredItemIds;
    return (this.group.items || [])
      .filter(i => !finishedItemIds.includes(i.bookingItemId || ""))
      .sort((a, b) =>
        this.role === "deliver" && a.checkedAt && !b.checkedAt ? -1 : 0
      );
  }

  get checkedItems() {
    const finishedItemIds =
      this.role === "produce" ? this.checkedItemIds : this.deliveredItemIds;
    const compareKey = this.role === "produce" ? "checkedAt" : "deliveredAt";
    return finishedItemIds
      .map(id => this.group.items?.find(i => i.bookingItemId === id))
      .sort((a, b) =>
        !a?.[compareKey] || !b?.[compareKey]
          ? 0
          : (a[compareKey] || "") < (b[compareKey] || "")
          ? -1
          : 1
      );
  }

  getMinutes(checkInAt: string, tick: Date) {
    return -moment(checkInAt, "HH:mm:ss")
      // .subtract(800, "minutes")
      .diff(tick, "minutes");
  }

  calcItemHeight() {
    const itemsElement = this.$refs.items as HTMLElement | undefined;
    if (!itemsElement) return;
    this.itemsScrollHeight = this.itemsClientHeight = NaN;
    this.itemsScrollHeight = itemsElement.scrollHeight;
    this.itemsClientHeight = Math.min(
      itemsElement.clientHeight,
      +(window.innerHeight / 2).toFixed() - 90
    );
    // console.log(
    //   "calcItemHeight",
    //   this.itemsScrollHeight,
    //   this.itemsClientHeight
    // );
    if (!this.expandable && this.expanded) {
      this.toggleExpand();
    }
  }

  async check(item: TodoProductGroupItem) {
    if (!this.group.items) return;
    let revoke = false;
    if (this.role === "deliver") {
      const delivered = this.deliveredItemIds.includes(
        item.bookingItemId || ""
      );
      if (!delivered) {
        this.deliveredItemIds.push(item.bookingItemId || "");
      } else {
        this.deliveredItemIds = this.deliveredItemIds.filter(
          id => id !== item.bookingItemId
        );
        revoke = true;
      }
    } else {
      const checked = this.checkedItemIds.includes(item.bookingItemId || "");
      if (!checked) {
        this.checkedItemIds.push(item.bookingItemId || "");
      } else {
        this.checkedItemIds = this.checkedItemIds.filter(
          id => id !== item.bookingItemId
        );
        revoke = true;
      }
    }
    navigator.vibrate && navigator.vibrate(100);
    this.$nextTick().then(() => {
      this.calcItemHeight();
    });
    this.$emit("check", {
      bookingId: item.bookingId,
      itemId: item.bookingItemId,
      revoke: revoke || undefined
    });
  }

  pinProduct(id: string) {
    this.$emit("pin-product", id);
  }

  mounted() {
    this.calcBookingMinutesInterval = window.setInterval(() => {
      this.tick = new Date();
    }, 10e3);
  }
  destroyed() {
    window.clearInterval(this.calcBookingMinutesInterval);
  }
}
