











































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { FlavorGroup, Flavor } from "@/resources/interfaces";

@Component
export default class FlavorGroups extends Vue {
  @Prop({ required: true })
  value!: FlavorGroup[];

  get groups() {
    return this.value;
  }

  set groups(v) {
    this.$emit("input", v);
  }

  addGroup() {
    if (!this.groups) {
      this.groups = [];
    }
    this.groups.push({ name: "", flavors: [] });
  }

  removeGroup(index: number) {
    if (!this.groups) {
      return;
    }
    this.groups = this.groups
      .slice(0, index)
      .concat(this.groups.slice(index + 1));
  }

  toggleGroup(group: FlavorGroup) {
    group.disabled = !group.disabled;
    this.groups = [...this.groups];
  }

  addFlavor(group: FlavorGroup) {
    group.flavors.push({ name: "", store: this.$user.store?.id });
  }

  removeFlavor(group: FlavorGroup, i: number) {
    group.flavors = group.flavors
      .slice(0, i)
      .concat(group.flavors.slice(i + 1));
  }

  flavorVisible(flavor: Flavor) {
    return (
      !flavor.store ||
      !this.$user.store ||
      flavor.store === this.$user.store?.id
    );
  }

  flavorEditable(flavor: Flavor) {
    return !this.$user.store?.id || flavor.store === this.$user.store?.id;
  }

  setFlavorExtraPrice(
    group: FlavorGroup,
    i: number,
    extraPrice: number | undefined
  ) {
    group.flavors[i].extraPrice =
      extraPrice || extraPrice === 0 ? extraPrice : undefined;
    group.flavors = [...group.flavors];
  }

  toggleStoreFlavor(group: FlavorGroup, i: number) {
    if (!this.$user.store) return;
    const flavor = group.flavors[i];
    if (!flavor.disabledStores) flavor.disabledStores = [];
    if (this.isStoreFlavorDisabled(flavor)) {
      flavor.disabledStores = flavor.disabledStores.filter(
        s => s !== this.$user.store?.id
      );
    } else {
      flavor.disabledStores.push(this.$user.store.id);
    }
    group.flavors = [...group.flavors];
  }

  isStoreFlavorDisabled(flavor: Flavor) {
    if (!this.$user.store) return;
    return (
      this.$user.id && flavor.disabledStores?.includes(this.$user.store.id)
    );
  }
}
