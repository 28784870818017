















































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Gift, Store } from "@/resources/interfaces";
import { GiftResource } from "@/resources";
import { Editor, Poster } from "@/components";
import { confirm } from "@/helpers/sweetAlert";

@Component({
  components: {
    Editor,
    Poster
  }
})
export default class GiftDetail extends Vue {
  gift: Partial<Gift> = { id: "", store: null };
  posterImage = "";
  async save() {
    this.gift = await GiftResource.save(this.gift);
    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });
    if (this.$route.params.id === "add") {
      this.$router.replace(`/gift/${this.gift.id}`);
    }
  }
  async remove() {
    if (
      !(await confirm(
        "确定要删除这个礼品",
        `这个操作不可撤销`,
        "确定删除",
        "error"
      ))
    )
      return;
    await GiftResource.delete({ id: this.gift.id });
    this.$router.go(-1);
  }
  @Watch("gift.store") onGiftStoreUpdate(v: Store | false) {
    if (typeof v === "object" && v) {
      // @ts-ignore
      this.gift.store = this.gift.store.id;
    } else if (v === false) {
      this.gift.store = null;
    }
  }
  async mounted() {
    if (this.$route.params.id !== "add") {
      this.gift = await GiftResource.get({ id: this.$route.params.id });
    }
  }
}
