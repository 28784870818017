import { render, staticRenderFns } from "./ColoredMinutes.vue?vue&type=template&id=14992248&scoped=true&lang=pug&"
import script from "./ColoredMinutes.vue?vue&type=script&lang=ts&"
export * from "./ColoredMinutes.vue?vue&type=script&lang=ts&"
import style0 from "./ColoredMinutes.vue?vue&type=style&index=0&id=14992248&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14992248",
  null
  
)

export default component.exports