











































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { Booking, BookingItem, BookingStatus } from "@/resources/interfaces";

@Component
export default class FoodTodoBookingCard extends Vue {
  @Prop({ required: true })
  value!: Booking;

  @Prop({ type: Boolean, default: false })
  finished!: boolean;

  @Prop({ type: String, default: "produce" })
  role!: "produce" | "deliver";

  expanded = false;
  finishing = false;
  minutes = NaN;
  itemsScrollHeight = NaN;
  itemsClientHeight = NaN;
  calcBookingMinutesInterval = 0;
  checkedItemIds: string[] = [];
  deliveredItemIds: string[] = [];

  get booking() {
    this.checkedItemIds =
      this.value.items?.filter(i => i.checkedAt).map(i => i._id || "") || [];
    this.deliveredItemIds =
      this.value.items?.filter(i => i.deliveredAt).map(i => i._id || "") || [];
    return this.value;
  }

  @Watch("booking", { immediate: true }) onBookingUpdate() {
    // console.log("onBookingUpdate", this.booking.id);
    if (!this.finished) {
      this.calcBookingMinutes();
      this.$nextTick().then(() => {
        this.calcItemHeight();
      });
    }
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  get expandable() {
    return this.itemsScrollHeight > this.itemsClientHeight;
  }

  get isLongTime() {
    return this.minutes >= 20;
  }

  get itemsHeight() {
    return this.expanded
      ? this.itemsScrollHeight + "px"
      : this.expandable
      ? this.itemsClientHeight + "px"
      : "";
  }

  get items() {
    const finishedItemIds =
      this.role === "produce" ? this.checkedItemIds : this.deliveredItemIds;
    if (this.finished) return this.booking.items || [];
    return (this.booking.items || [])
      .filter(i => !finishedItemIds.includes(i._id || ""))
      .sort((a, b) =>
        this.role === "deliver" && a.checkedAt && !b.checkedAt ? -1 : 0
      );
  }

  get checkedItems() {
    const finishedItemIds =
      this.role === "produce" ? this.checkedItemIds : this.deliveredItemIds;
    const compareKey = this.role === "produce" ? "checkedAt" : "deliveredAt";
    return finishedItemIds
      .map(id => this.booking.items?.find(i => i._id === id))
      .sort((a, b) =>
        !a?.[compareKey] || !b?.[compareKey]
          ? 0
          : (a[compareKey] || "") < (b[compareKey] || "")
          ? -1
          : 1
      );
  }

  calcItemHeight() {
    const itemsElement = this.$refs.items as HTMLElement | undefined;
    if (!itemsElement) return;
    this.itemsScrollHeight = this.itemsClientHeight = NaN;
    this.itemsScrollHeight = itemsElement.scrollHeight;
    this.itemsClientHeight = Math.min(
      itemsElement.clientHeight,
      +(window.innerHeight / 2).toFixed() - 90
    );
    // console.log(
    //   "calcItemHeight",
    //   this.itemsScrollHeight,
    //   this.itemsClientHeight
    // );
    if (!this.expandable && this.expanded) {
      this.toggleExpand();
    }
  }

  calcBookingMinutes() {
    // console.log(
    //   "calcBookingMinutes",
    //   this.booking.tableId || "P" + this.booking.pagerId
    // );
    this.minutes = moment().diff(
      moment(this.booking.checkInAt, "HH:mm:ss"),
      "minutes"
    );
  }

  async check(item: BookingItem) {
    if (!this.booking.items || this.finished) return;
    let revoke = false;
    if (this.role === "deliver") {
      const delivered = this.deliveredItemIds.includes(item._id || "");
      if (!delivered) {
        this.deliveredItemIds.push(item._id || "");
      } else {
        this.deliveredItemIds = this.deliveredItemIds.filter(
          id => id !== item._id
        );
        revoke = true;
      }
    } else {
      const checked = this.checkedItemIds.includes(item._id || "");
      if (!checked) {
        this.checkedItemIds.push(item._id || "");
      } else {
        this.checkedItemIds = this.checkedItemIds.filter(id => id !== item._id);
        revoke = true;
      }
    }
    navigator.vibrate && navigator.vibrate(100);
    this.$nextTick().then(() => {
      this.calcItemHeight();
    });
    this.$emit("check", {
      bookingId: this.booking.id,
      itemId: item._id,
      revoke: revoke || undefined
    });
  }

  finish() {
    // this.booking.status = BookingStatus.FINISHED;
    this.finishing = true;
    navigator.vibrate && navigator.vibrate(100);
    this.$emit("finish");
  }

  recover() {
    this.booking.status = BookingStatus.IN_SERVICE;
    navigator.vibrate && navigator.vibrate(100);
    this.$emit("recover");
  }

  mounted() {
    if (!this.finished) {
      this.calcBookingMinutesInterval = window.setInterval(
        this.calcBookingMinutes,
        30e3
      );
    }
  }
  destroyed() {
    window.clearInterval(this.calcBookingMinutesInterval);
  }
}
